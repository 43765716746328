import React from "react";
import { Form, Field } from "react-final-form";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import "react-google-places-autocomplete/dist/index.min.css";

const onSubmit = async (values) => {
  window.alert(JSON.stringify(values, 0, 2));
};

const Details = () => {
  return (
    <div className="article payment-details">
      <div className="donation-slider">
        <h3>Details</h3>
      </div>
      <Form
        onSubmit={onSubmit}
        initialValues={{ stooge: "larry", employed: false }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form className="form" onSubmit={handleSubmit}>
            <div className="form__row form__row--3-col">
              <div className="input">
                <label>Title</label>
                <Field name="title" component="select">
                  <option />
                  <option value="mr">Mr</option>
                  <option value="mrs">Mrs</option>
                  <option value="miss">Miss</option>
                  <option value="ms">Ms</option>
                  <option value="other">Other</option>
                </Field>
              </div>
              <div className="input">
                <label>First Name</label>
                <Field name="firstName" component="input" type="text" />
              </div>
              <div className="input">
                <label>Last Name</label>
                <Field name="lastName" component="input" type="text" />
              </div>
            </div>
            <div className="form__row form__row--2-col">
              <div className="input">
                <label>Email</label>
                <Field name="email" component="input" type="email" />
              </div>
              <div className="input">
                <label>Phone</label>
                <Field name="phone-number" component="input" type="phone" />
              </div>
            </div>
            <div className="form__row">
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ["nz"],
                  },
                }}
              />
            </div>
            <div className="form__row">
              <div className="input">
                <label>Address Line 1</label>
                <Field name="address" component="input" type="text" />
              </div>
            </div>
            <div className="form__row">
              <div className="input">
                <label>Address Line 2</label>
                <Field name="address" component="input" type="text" />
              </div>
            </div>
            <div className="form__row form__row--2-col">
              <div className="input">
                <label>City</label>
                <Field name="city" component="input" type="text" />
              </div>
              <div className="input">
                <label>Postcode</label>
                <Field name="postcode" component="input" type="number" />
              </div>
            </div>
            <div className="form__row form__row--2-col">
              <div className="input">
                <label>Region</label>
                <Field name="region" component="input" type="text" />
              </div>
              <div className="input">
                <label>Country</label>
                <Field name="country" component="input" type="text" />
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default Details;
