import React, { Component } from "react";
import { ShareButton, DonateButton } from "../buttons";
import Markdown from "react-markdown";
import FadeIn from "react-fade-in";

import Service from "../../api";

const fetchedProjectData = [{ fetchedProjectData: "" }];

class Project extends Component {
  state = {
    fetchedProjectData,
  };

  componentDidMount() {
    this.fetchProjectData();
  }

  async fetchProjectData() {
    try {
      const projectContent = await Service.get("project");
      const fetchedProjectData = projectContent.data;
      this.setState({ fetchedProjectData });
    } catch (e) {
      console.error(e);
    }
  }
  render() {
    const data = this.state.fetchedProjectData;
    return (
      <FadeIn childClassName="fade">
        <div className="article section top-section">
          <div className="container">
            <span className="tag">The Project</span>
            <div className="article__wrap">
              <div className="article__content">
                <h3>{data.projectIntro}</h3>
                <Markdown source={data.projectMain} />
              </div>
              <div className="article__sidebar">
                <div className="article__sidebar-header">
                  Help us reach our goal!
                </div>
                <div className="button-group">
                  <ShareButton />
                  <DonateButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </FadeIn>
    );
  }
}

export default Project;
