import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MdMenu, MdClose } from "react-icons/md";
import logo from "../assets/images/hohepa-logo-220.png";

const MobileNavigation = () => {
  const [active, setActive] = useState("");
  const [navIsOpen, setNavIsOpen] = useState(false);

  const handleClose = () => setNavIsOpen(false);

  const handleClick = () => setNavIsOpen(true);

  const items = [
    { name: "About Hohepa" },
    { name: "The Project" },
    { name: "Our Community" },
    { name: "Our Supporters" },
    { name: "Donate" },
  ];

  const list = items.map(({ name }) => (
    <li key={name} onClick={handleClose} className={active === name ? "mobile-main-nav__item active" : "mobile-main-nav__item"}>
      <Link to={`/${name.replace(/\s/g, "-").toLowerCase()}`}>{name}</Link>
    </li>
  ));

  return (
    <div className="main-nav mobile-main-nav">
      <div className="container main-nav__wrap">
        {navIsOpen ? (
          <div className="main-nav__menu" onClick={handleClose}>
            <MdClose size={26} />
          </div>
        ) : (
          <div className="main-nav__menu" onClick={handleClick}>
            <MdMenu size={26} />
          </div>
        )}
        <div className="main-nav__logo">
          <Link onClick={() => setActive("home")} to="/">
            <img src={logo} alt="Hohepa Logo" />
          </Link>
        </div>
      </div>
      <div className={`mobile-main-nav__panel ${navIsOpen ? "show" : "hide"}`} onClose={handleClose}>
        <ul className="mobile-main-nav__list">{list}</ul>
      </div>
    </div>
  );
};

export default MobileNavigation;
