import React, { useState } from "react";
import { Button } from "@blueprintjs/core";
import { Link } from "react-router-dom";
import { MdMenu } from "react-icons/md";

import logo from "../assets/images/hohepa-logo-220.png";

const Navigation = () => {
  const [active, setActive] = useState("");

  const items = [
    { name: "About Hōhepa", link: "about-hohepa" },
    { name: "The Project", link: "the-project" },
    { name: "Our Community", link: "our-community" },
    { name: "Our Supporters", link: "our-supporters" },
  ];

  const list = items.map(({ name, link }) => (
    <li key={link} onClick={() => setActive(name)} className={active === name ? "main-nav__item active" : "main-nav__item"}>
      <Link to={`/${link}`}>{name}</Link>
    </li>
  ));

  return (
    <div className="main-nav">
      <div className="container main-nav__wrap">
        <div className="main-nav__menu">
          <MdMenu size={26} />
        </div>
        <div className="main-nav__logo">
          <Link onClick={() => setActive("home")} to="/">
            <img src={logo} alt="Hohepa Logo" />
          </Link>
        </div>
        <ul className="main-nav__list">
          {list}
          <li className="main-nav__item">
            <Link onClick={() => setActive("donate")} className={active === "donate" ? "active-btn" : ""} to="/donate">
              <Button className="button" text="Donate" icon="heart" />
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navigation;
