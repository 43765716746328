import React, { Component } from "react";
import PropTypes from "prop-types";
import DonationSlider from "../donationSlider";
import Markdown from "react-markdown";

class Donate extends Component {
  render() {
    return (
      <div className="donate donate-home section">
        <div className="donate__wrap container">
          <div className="section__header">
            <h3>
              <span>Donate</span>
            </h3>
          </div>
          <Markdown source={this.props.donateText} />
          <DonationSlider value={this.props.value} />
        </div>
      </div>
    );
  }
}

Donate.propTypes = {
  value: PropTypes.number,
};

Donate.defaultProps = {
  value: 500,
};

export default Donate;
