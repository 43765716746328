import React from "react";
import { ShareButton } from "../buttons";
import ModalVideo from "react-modal-video";
import FadeIn from "react-fade-in";
import { FaRegPlayCircle } from "react-icons/fa";

import Service from "../../api";
import BruceKieran from "../../assets/images/bruce_keiran.jpg";
import Jessie from "../../assets/images/jessie.jpg";
import Shaun from "../../assets/images/shaun.jpg";
import Zane from "../../assets/images/zane.jpg";
import Residents from "../../assets/images/residents.jpg";

const fetchedCommunityData = [{ fetchedCommunityData: "" }];
class Community extends React.Component {
  state = {
    fetchedCommunityData,
  };

  componentDidMount() {
    this.fetchCommunityData();
  }

  async fetchCommunityData() {
    try {
      const communityContent = await Service.get("community");
      const fetchedCommunityData = communityContent.data;
      this.setState({ fetchedCommunityData });
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    const data = this.state.fetchedCommunityData;
    return (
      <FadeIn childClassName="fade">
        <div className="article section top-section">
          <div className="container">
            <span className="tag">Our Community</span>
            <div className="community__wrap">
              <h5 className="community__subhead">{data.communityIntro}</h5>
              <div className="community__block community__block--left first">
                <div className="community__image">
                  <div>
                    <ModalVideo
                      channel="vimeo"
                      isOpen={this.state.isOpenVimeo === "453714493"}
                      videoId="453714493"
                      onClose={() => this.setState({ isOpenVimeo: false })}
                    />
                    <div
                      className="video-placeholder"
                      onClick={() =>
                        this.setState({ isOpenVimeo: "453714493" })
                      }
                    >
                      <FaRegPlayCircle size={80} color="#fff" />
                      <img src={Jessie} alt="community 123" />
                    </div>
                  </div>
                </div>
                <div className="community__text">
                  <h4>{data.videoTitleOne}</h4>
                  <p>{data.videoBlurbOne}</p>
                </div>
                <div className="community__share">
                  <ShareButton />
                </div>
              </div>
              <div className="community__block community__block--right">
                <div className="community__share">
                  <ShareButton />
                </div>
                <div className="community__text">
                  <h4>{data.videoTitleTwo}</h4>
                  <p>{data.videoBlurbTwo}</p>
                </div>
                <div className="community__image">
                  <div>
                    <ModalVideo
                      channel="vimeo"
                      isOpen={this.state.isOpenVimeo === "453714873"}
                      videoId="453714873"
                      onClose={() => this.setState({ isOpenVimeo: false })}
                    />
                    <div
                      className="video-placeholder"
                      onClick={() =>
                        this.setState({ isOpenVimeo: "453714873" })
                      }
                    >
                      <FaRegPlayCircle size={80} color="#fff" />
                      <img src={Shaun} alt="community" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="community__block community__block--left first">
                <div className="community__image">
                  <div>
                    <ModalVideo
                      channel="vimeo"
                      isOpen={this.state.isOpenVimeo === "453707504"}
                      videoId="453707504"
                      onClose={() => this.setState({ isOpenVimeo: false })}
                    />
                    <div
                      className="video-placeholder"
                      onClick={() =>
                        this.setState({ isOpenVimeo: "453707504" })
                      }
                    >
                      <FaRegPlayCircle size={80} color="#fff" />
                      <img src={Residents} alt="community" />
                    </div>
                  </div>
                </div>
                <div className="community__text">
                  <h4>{data.videoTitleThree}</h4>
                  <p>{data.videoBlurbThree}</p>
                </div>
                <div className="community__share">
                  <ShareButton />
                </div>
              </div>
              <div className="community__block community__block--right">
                <div className="community__share">
                  <ShareButton />
                </div>
                <div className="community__text">
                  <h4>{data.videoTitleFour}</h4>
                  <p>{data.videoBlurbFour}</p>
                </div>
                <div className="community__image">
                  <div>
                    <ModalVideo
                      channel="vimeo"
                      isOpen={this.state.isOpenVimeo === "453715631"}
                      videoId="453715631"
                      onClose={() => this.setState({ isOpenVimeo: false })}
                    />
                    <div
                      className="video-placeholder"
                      onClick={() =>
                        this.setState({ isOpenVimeo: "453715631" })
                      }
                    >
                      <FaRegPlayCircle size={80} color="#fff" />
                      <img src={Zane} alt="community" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="community__block community__block--left">
                <div className="community__image">
                  <div>
                    <ModalVideo
                      channel="vimeo"
                      isOpen={this.state.isOpenVimeo === "453706884"}
                      videoId="453706884"
                      onClose={() => this.setState({ isOpenVimeo: false })}
                    />
                    <div
                      className="video-placeholder"
                      onClick={() =>
                        this.setState({ isOpenVimeo: "453706884" })
                      }
                    >
                      <FaRegPlayCircle size={80} color="#fff" />
                      <img src={BruceKieran} alt="community" />
                    </div>
                  </div>
                </div>
                <div className="community__text">
                  <h4>{data.videoTitleFive}</h4>
                  <p>{data.videoBlurbFive}</p>
                </div>
                <div className="community__share">
                  <ShareButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </FadeIn>
    );
  }
}

export default Community;
