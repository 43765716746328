import React from "react";

import Sutherland from "../../assets/images/sponsors/sutherland-self-help.jpg";
import Lamar from "../../assets/images/sponsors/lamar.jpg";

function Sponsors() {
  return (
    <div className="sponsors sponsors-home section">
      <div className="sponsors__wrap container">
        <div className="section__header">
          <h4>Our Supporters </h4>
        </div>
        <div className="major-sponsors__list">
          <div className="major-sponsors__logo">
            <img src={Lamar} alt="logo" />
          </div>
          <div className="major-sponsors__logo">
            <img src={Sutherland} alt="logo" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sponsors;
