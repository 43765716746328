// Define the constants for the application
const constants = {
  // App
  appName: "Hōhepa Community Campaign",

  // Widgets
  donationWidgetUrl:
    "https://connect.vega.works/(S(khcnmupdy5a05fljzhthrnlw))/Content/csharp/DonateFormAll.aspx?rfD3x0k=084A89DF-98EC-4427-BFE0-FDD8535EA6ED&xlnse5=2071",
};

export default constants;
