import React from "react";
import Cards from "react-credit-cards";

import { formatCreditCardNumber, formatCVC, formatExpirationDate } from "../../helpers/cardUtils";

import "react-credit-cards/es/styles-compiled.css";

class Payment extends React.Component {
  state = {
    number: "",
    name: "",
    expiry: "",
    cvc: "",
    issuer: "",
    focused: "",
    formData: null,
  };

  handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      this.setState({ issuer });
    }
  };

  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name,
    });
  };

  handleInputChange = ({ target }) => {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
    }

    this.setState({ [target.name]: target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const formData = [...e.target.elements]
      .filter((d) => d.name)
      .reduce((acc, d) => {
        acc[d.name] = d.value;
        return acc;
      }, {});

    this.setState({ formData });
    this.form.reset();
  };

  render() {
    return (
      <div className="payment payment-details">
        <div className="donation-slider">
          <h3>Payment</h3>
        </div>
        <div className="payment-form" id="PaymentForm">
          <Cards
            cvc={this.state.cvc}
            expiry={this.state.expiry}
            focused={this.state.focus}
            name={this.state.name}
            number={this.state.number}
          />
          <form className="payment-form__wrap form" ref={(c) => (this.form = c)} onSubmit={this.handleSubmit}>
            <div className="form-group form__row">
              <div className="input">
                <label>Name on Card</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  required
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </div>
            </div>
            <div className="form-group form__row">
              <div className="input">
                <label>Credit or Debit Card Number</label>
                <input
                  type="tel"
                  name="number"
                  className="form-control"
                  pattern="[\d| ]{16,22}"
                  required
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </div>
            </div>
            <div className="row input form__row form__row--2-col">
              <div className="col-6">
                <label>Expiry</label>
                <input
                  type="tel"
                  name="expiry"
                  className="form-control"
                  placeholder="mm/yy"
                  pattern="\d\d/\d\d"
                  required
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </div>
              <div className="col-6">
                <label>Expiry</label>
                <input
                  type="tel"
                  name="cvc"
                  className="form-control"
                  pattern="\d{3,4}"
                  required
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </div>
            </div>
            <input type="hidden" name="issuer" value={this.state.issuer} />
          </form>
        </div>
      </div>
    );
  }
}

export default Payment;
