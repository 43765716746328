import React from "react";
import Donation from "./donation";
import FadeIn from "react-fade-in";

const Donate = ({ value }) => {
  return (
    <FadeIn childClassName="fade">
      <div className="article section donation-wrap">
        <div className="container top-section">
          <Donation />
        </div>
      </div>
    </FadeIn>
  );
};

export default Donate;
