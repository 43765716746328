import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import constants from "../../config/constants";
import DonationSlider from "../donationSlider";
import { MdClose } from "react-icons/md";

const Donation = ({ donationWidgetUrl, value }) => {
  const iframeElement = useRef();
  const [openWidget, setOpenWidget] = useState(false);
  const [initiateWidget, setInitiateWidget] = useState(true);
  const [donationValue, setDonationValue] = useState(value);

  const handleDonationSliderChange = (value) => {
    const valueFloat = parseFloat(value);

    const data = {
      init: initiateWidget,
      "#MainContent_txtAmount": valueFloat,
    };

    setDonationValue(valueFloat);

    if (iframeElement && iframeElement.current.contentWindow) {
      console.log("ATTEMPTING TO SEND MESSAGE : ", JSON.stringify(data));
      iframeElement.current.contentWindow.postMessage(JSON.stringify(data), "https://connect.vega.works");
    } else {
      console.log("FAILED TO SEND MESSAGE : ", JSON.stringify(data));
    }
  };

  const handleDonateButtonClick = () => {
    !openWidget ? setOpenWidget(true) : setOpenWidget(false);

    if (window.ga) {
      window.ga("send", "event", "Donate", "Show Form", donationValue);
    }

    // The widget should be initated in the first postMessage. Don't do it again.
    setInitiateWidget(!initiateWidget);

    // On showing the widget, force an update on the slider and force the widget to init.
    handleDonationSliderChange(donationValue);
  };

  return (
    <div className="donate payment section">
      <div className="payment__wrap">
        <div className={`left-col ${openWidget && "active"}`}>
          <div className="section__header">
            <h3>
              <span>Donate</span>
            </h3>
          </div>
          <DonationSlider
            value={donationValue}
            onDonationSliderChange={handleDonationSliderChange}
            onDonateButtonClick={handleDonateButtonClick}
            openWidget={openWidget}
          />
          <div className="disclaimer">
            Please note, that while 100% of donations raised will go directly towards the fit out of the two new homes for this stated
            purpose, distribution of the funds is at Hōhepa Canterbury’s discretion. The specific items displayed are indicative only of the
            types of costs involved in the fit out, and these funds may be used for a range of costs associated with the fit out.
          </div>
        </div>
        <div className={`right-col ${openWidget && "active"}`}>
          <span className="close-widget">
            <MdClose size={26} onClick={() => setOpenWidget(false)} />
          </span>
          <iframe
            id="vegaDonation"
            title="Vega Donation Widget"
            src={`${donationWidgetUrl}`}
            width="100%"
            height="800px"
            frameBorder={0}
            ref={iframeElement}
            referrerpolicy="always"
            onload="this.contentWindow.postMessage(window.location.href, 'https://connect.vega.works')"
          />
        </div>
      </div>
    </div>
  );
};

Donation.propTypes = {
  donationWidgetUrl: PropTypes.string,
  value: PropTypes.number,
};

Donation.defaultProps = {
  donationWidgetUrl: constants.donationWidgetUrl,
  value: 500,
};

export default Donation;
