import React, { Component } from "react";
import PropTypes from "prop-types";

import StepRangeSlider from "react-step-range-slider";
import NumberFormat from "react-number-format";
import { DonateButton } from "./buttons";

import CoffeeMugs from "../assets/images/coffee-mugs.png";
import Towels from "../assets/images/towels.png";
import Duvet from "../assets/images/duvet.png";
import Desk from "../assets/images/desk.png";
import QueenBed from "../assets/images/queen-bed.png";
import Fridge from "../assets/images/fridge.png";
import Hoist from "../assets/images/hoist.png";
import MobilityAid from "../assets/images/sara-stedy-mobility-aid.png";
import "react-rangeslider/lib/index.css";

class DonationSlider extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      value: props.value,
      customValue: props.value,
      image: Hoist,
    };
  }

  handleChange = (value, image) => {
    const valueFormatted = value.toLocaleString(navigator.language, { maximumFractionDigits: 2 }).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    this.setState({
      value: value,
      image: image,
      customValue: valueFormatted,
    });

    // Push the value up to the parent component.
    this.props.onDonationSliderChange(value);
  };

  handleInputChange = (event) => {
    const value = event.target.value;
    const valueRaw = value.toString().replace(/[^\d.-]/g, "");
    const valueFormatted = valueRaw.toLocaleString(navigator.language, { maximumFractionDigits: 2 }).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    this.setState({
      customValue: valueFormatted,
    });

    // Push the value up to the parent component.
    this.props.onDonationSliderChange(valueRaw);
  };

  render() {
    const { value, customValue } = this.state;

    const range = [
      {
        value: 20,
        step: 30,
        image: CoffeeMugs,
        description: "a set of coffee mugs",
      }, // acts as min value
      {
        value: 50,
        step: 100,
        image: Towels,
        description: "an individual bathroom towel and flannel set",
      },
      {
        value: 150,
        step: 350,
        image: Duvet,
        description: "a cozy duvet (inner & cover)",
      },
      {
        value: 500,
        step: 250,
        image: Desk,
        description: "a desk for quiet reflection time or to play fun games",
      },
      {
        value: 750,
        step: 450,
        image: QueenBed,
        description: "a queen bed, fit for a queen or king!",
      },
      {
        value: 1200,
        step: 1800,
        image: Fridge,
        description: "a fridge to keep meals and snacks fresh",
      },
      {
        value: 3000,
        step: 2000,
        image: MobilityAid,
        description: "a special Sara Stedy standing and raising mobility aid",
      },
      {
        value: 5000,
        image: Hoist,
        description: "a hoist to assist with lifting and moving those with high physical needs",
      },
    ];

    const stepDescription = range.map((range, idx) => <span key={idx}>{value === range.value && range.description}</span>);
    const stepImage = range.map((range, idx) => value === range.value && <img key={idx} src={range.image} alt={range.description} />);

    return (
      <>
        <div className="donation-slider">
          <h3>
            Your donation of
            <span className="large">
              <NumberFormat value={value} displayType={"text"} thousandSeparator={true} prefix={"$"} />
            </span>
            could fit out our new homes with {stepDescription}
          </h3>
          <div className="donation-slider__image">{stepImage}</div>
          <div className={`range-slider ${this.props.openWidget === true && "active"}`}>
            <StepRangeSlider range={range} value={value} onChange={this.handleChange} />
            <div className="donation-slider__block">
              <div className="input donation-slider__amount">
                <label>Other Amount</label>
                <div className="input-group">
                  <span className="input-group__currency">NZD $</span>
                  <input
                    className="donation-slider__input"
                    id="donation-amount"
                    name="donation-amount"
                    type="text"
                    value={customValue}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <DonateButton onClick={(event) => this.props.onDonateButtonClick(event)} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

DonationSlider.propTypes = {
  value: PropTypes.number,
  onDonationSliderChange: PropTypes.func,
  onDonateButtonClick: PropTypes.func,
};

DonationSlider.defaultProps = {
  value: 50,
  onDonationSliderChange: () => {},
  onDonateButtonClick: () => {},
};

export default DonationSlider;
