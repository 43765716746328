import React from "react";
import { ShareButton, DonateButton } from "../buttons";

class Tracker extends React.Component {
  render() {
    const percent = `${this.props.percentage}%`;
    return (
      <div className="tracker">
        <div className="tracker__wrap container">
          <div className="tracker__block">
            <h4>{this.props.title}</h4>
            <div className="tracker__bar">
              <div
                className="tracker__bar--inner"
                style={{ width: percent }}
              ></div>
            </div>
            <p>
              Given by <span className="bold">{this.props.donors}</span>{" "}
              generous donors!
            </p>
          </div>
          <div className="button-group">
            <ShareButton />
            <DonateButton />
          </div>
        </div>
      </div>
    );
  }
}

export default Tracker;
