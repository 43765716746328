import React, { Component } from "react";
import previewImage from "../../assets/images/group-photo.jpg";
import Markdown from "react-markdown";

class Preview extends Component {
  render() {
    return (
      <div className="preview section">
        <div className="preview__wrap container">
          <div className="preview__block preview__block--left">
            <div>
              <Markdown source={this.props.introduction} />
            </div>
            <div>
              <img src={previewImage} alt="" />
            </div>
          </div>
          <div>
            <Markdown source={this.props.keypoints} />
          </div>
        </div>
      </div>
    );
  }
}

export default Preview;
