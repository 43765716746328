import React, { Component } from "react";
import { ShareButton, DonateButton } from "../buttons";
import Service from "../../api";
import Markdown from "react-markdown";
import FadeIn from "react-fade-in";

const fetchedAboutData = [{ fetchedAboutData: "" }];
class About extends Component {
  state = {
    fetchedAboutData,
  };

  componentDidMount() {
    this.fetchAboutData();
  }

  async fetchAboutData() {
    try {
      const aboutContent = await Service.get("about");
      const fetchedAboutData = aboutContent.data;
      this.setState({ fetchedAboutData });
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    const data = this.state.fetchedAboutData;
    return (
      <FadeIn childClassName="fade">
        <div className="article section top-section">
          <div className="container">
            <span className="tag">About Hōhepa</span>
            <div className="article__wrap">
              <div className="article__content">
                <h3>{data.aboutIntro}</h3>
                <Markdown source={data.aboutMain} />
              </div>
              <div className="article__sidebar">
                <div className="article__sidebar-header">
                  Help us reach our goal!
                </div>
                <div className="button-group">
                  <ShareButton />
                  <DonateButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </FadeIn>
    );
  }
}

export default About;
