import React, { Component, Fragment } from "react";
import FadeIn from "react-fade-in";
import Hero from "./hero";
import Tracker from "./tracker";
import Preview from "./preview";
import Donate from "./donate";
import Community from "./community";
import Sponsors from "./sponsors";
import Service from "../../api";

const fetchedHomeData = [{ fetchedHomeData: "" }];
const fetchedCommunityData = [{ fetchedCommunityData: "" }];

class Home extends Component {
  state = {
    fetchedHomeData,
    fetchedCommunityData,
  };

  componentDidMount() {
    this.fetchContent();
  }

  async fetchContent() {
    try {
      const homeContent = await Service.get("homepage");
      const communityContent = await Service.get("community");
      const fetchedHomeData = homeContent.data;
      const fetchedCommunityData = communityContent.data;
      this.setState({ fetchedHomeData, fetchedCommunityData });
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    const fetchedHomeData = this.state.fetchedHomeData;
    const fetchedCommunityData = this.state.fetchedCommunityData;
    return (
      <Fragment>
        <FadeIn childClassName="fade">
          <Hero
            heroTitle={fetchedHomeData.headline}
            heroSubtitle={fetchedHomeData.subheading}
          />
          <Tracker
            title={fetchedHomeData.progressBarTitle}
            percentage={fetchedHomeData.progressBarPercentage}
            donors={fetchedHomeData.progressBarTotalDonors}
          />
          <Preview
            introduction={fetchedHomeData.introduction}
            keypoints={fetchedHomeData.keypoints}
          />
          <Donate donateText={fetchedHomeData.donate} />
          <Community fetchedCommunityData={fetchedCommunityData} />
          <Sponsors />
        </FadeIn>
      </Fragment>
    );
  }
}

export default Home;
