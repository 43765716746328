import * as React from "react";
import { withRouter } from "react-router-dom";

class Analytics extends React.Component {
  constructor(props) {
    super(props);

    this.sendPageView = this.sendPageView.bind(this);
    this.initialize = this.initialize.bind(this);

    this.initialize(props.id);
  }

  componentDidMount() {
    this.sendPageView(this.props.location);
    this.props.history.listen(this.sendPageView);
  }

  initialize() {
    if (!this.props.id) {
      console.error("[google-analytics] Tracking ID is required.");
      return;
    }

    // Load Google Analytics
    /*eslint-disable */
    (function (i, s, o, g, r, a, m) {
      i["GoogleAnalyticsObject"] = r;
      (i[r] =
        i[r] ||
        function () {
          (i[r].q = i[r].q || []).push(arguments);
        }),
        (i[r].l = 1 * new Date());
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(window, document, "script", "https://www.google-analytics.com/analytics.js", "ga");
    /*eslint-enable */

    // Initialize Google Analytics
    window.ga("create", this.props.id, "auto");
  }

  sendPageView(location) {
    // Do nothing if GA was not initialized due to a missing tracking ID.
    if (!window.ga) {
      return;
    }

    // Do nothing if trackPathnameOnly is enabled and the pathname didn't change.
    if (this.props.trackPathnameOnly && location.pathname === this.lastPathname) {
      return;
    }

    this.lastPathname = location.pathname;

    // Sets the page value on the tracker. If a basename is provided, then it is prepended to the pathname.
    const page = this.props.basename ? `${this.props.basename}${location.pathname}` : location.pathname;

    window.ga("set", "page", page);

    // Sending the pageview no longer requires passing the page
    // value since it's now stored on the tracker object.
    window.ga("send", "pageview");

    if (this.props.debug) {
      console.info(`[google-analytics] Page view: ${page}`);
    }
  }

  render() {
    return this.props.children;
  }
}

Analytics.defaultProps = {
  debug: false,
};

export default withRouter(Analytics);
