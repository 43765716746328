import React from "react";
import { MdLocationOn } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { FaFacebook } from "react-icons/fa";
import { Link } from "react-router-dom";

import HohepaLogoWhite from "../assets/images/hohepa-logo-white.png";

function Footer() {
  return (
    <div className="footer section">
      <div className="footer__wrap container">
        <div className="footer__logo">
          <a
            href="https://www.hohepacanterbury.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={HohepaLogoWhite} alt="logo" />
            <div>www.hohepacanterbury.com</div>
          </a>
        </div>
        <div className="footer__block">
          <div className="footer__header">Contact</div>
          <ul>
            <li className="footer__icon-row">
              <span>
                <MdLocationOn size={20} />
              </span>
              <a href="https://goo.gl/maps/FD8wGBpyprevVXuH8" target="__blank">
                23 Barrington Street <br />
                Somerfield, Christchurch
              </a>
            </li>
            <li className="footer__icon-row">
              <span>
                <MdEmail size={20} />
              </span>
              <a href="mailto:info@hohepacanterbury.com">
                info@hohepacanterbury.com
              </a>
            </li>
          </ul>
        </div>
        <div className="footer__block">
          <div className="footer__header">Hōhepa</div>
          <ul>
            <li>
              <Link to={`/the-project`}>The Project</Link>
            </li>
            <li>
              <Link to={`/our-community`}>Our Community</Link>
            </li>
            <li>
              <Link to={`/donate`}>Donate</Link>
            </li>
            <li>
              <Link to={`/our-supporters`}>Sponsors &amp; Supporters</Link>
            </li>
          </ul>
        </div>
        <div className="footer__right">
          <div className="footer__social">
            <a
              href="https://www.facebook.com/Hohepa-Canterbury-116570891841820"
              target="_blank"
              rel="noopener noreferrer"
            >
              Visit us on Facebook{" "}
              <span>
                <FaFacebook size={26} />
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="container credit">
        <a href="https://www.customd.com/" target="__blank">
          Design &amp; Development by Custom D
        </a>
      </div>
    </div>
  );
}

export default Footer;
