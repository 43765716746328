import React, { useState } from "react";
import { Dialog } from "@blueprintjs/core";
import { FaArrowRight, FaArrowLeft, FaHeart, FaShareAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookIcon,
  TwitterIcon,
  EmailIcon,
} from "react-share";

export const PrevButton = () => {
  return (
    <div className="circular-button circular-button--ghost">
      <FaArrowLeft size={22} />
      <span>Back</span>
    </div>
  );
};

export const NextButton = () => {
  return (
    <div className="circular-button">
      <FaArrowRight size={22} />
      <span>Next</span>
    </div>
  );
};

export const DonateButton = ({ onClick }) => {
  return (
    <Link to={`/donate`} onClick={onClick} className="button-mobile">
      <div className="circular-button">
        <FaHeart size={22} />
        <span>Donate</span>
      </div>
    </Link>
  );
};

export const ShareButton = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleClose = () => setModalIsOpen(false);

  const handleClick = () => setModalIsOpen(true);
  return (
    <div className="button-mobile">
      <div className="circular-button" onClick={handleClick}>
        <FaShareAlt size={22} />
        <span>Share</span>
      </div>
      <Dialog
        className="share-modal"
        title="Share our campaign with your family and friends!"
        isOpen={modalIsOpen}
        onClose={handleClose}
        isCloseButtonShown={false}
      >
        <div className="share-modal__wrap">
          <div className="bp3-dialog-body">
            <TwitterShareButton
              url="https://donations.hohepacanterbury.com/"
              quote="Donate to Hōhepa Canterbury today!"
              className="Demo__some-network__share-button"
            >
              <TwitterIcon size={50} round />
            </TwitterShareButton>
            <FacebookShareButton
              url="https://donations.hohepacanterbury.com/"
              quote="Donate to Hōhepa Canterbury today!"
              className="Demo__some-network__share-button"
            >
              <FacebookIcon size={50} round />
            </FacebookShareButton>
            <LinkedinShareButton
              url="https://donations.hohepacanterbury.com/"
              quote="Donate to Hōhepa Canterbury today!"
              className="Demo__some-network__share-button"
            >
              <LinkedinIcon size={50} round />
            </LinkedinShareButton>
            <EmailShareButton
              url="https://donations.hohepacanterbury.com/"
              subject="Donate to Hōhepa Canterbury today!"
              body="Everyone needs and deserves a home they cherish and from where they can dream, be and do. Help Hōhepa Canterbury fit out its two new homes to enable every life to be fully lived."
              className="Demo__some-network__share-button"
            >
              <EmailIcon size={50} round />
            </EmailShareButton>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
