import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { layoutGenerator } from "react-break";
import ScrollToTop from "./ScrollToTop";
import Analytics from "./helpers/Analytics";

import Navigation from "./components/navigation";
import MobileNavigation from "./components/mobileNavigation";
import Home from "./components/Home/index";
import About from "./components/About/index";
import Project from "./components/Project/index";
import OurCommunity from "./components/OurCommunity/index";
import OurSupporters from "./components/OurSupporters/index";
import Donate from "./components/Donate/index";
import Details from "./components/Donate/details";
import Payment from "./components/Donate/payment";
import Footer from "./components/footer";

import "./styles/App.scss";

class App extends Component {
  render() {
    const layout = layoutGenerator({
      mobile: 0,
      desktop: 992,
    });

    const OnMobile = layout.is("mobile");
    const OnDesktop = layout.is("desktop");

    return (
      <BrowserRouter>
        <Analytics id={process.env.REACT_APP_GOOGLE_ANALYTICS} trackPathnameOnly>
          <ScrollToTop>
            <div>
              <OnMobile>
                <MobileNavigation />
              </OnMobile>
              <OnDesktop>
                <Navigation />
              </OnDesktop>
              <Switch>
                <Route path="/" component={Home} exact />
                <Route path="/about-hohepa" component={About} />
                <Route path="/the-project" component={Project} />
                <Route path="/our-community" component={OurCommunity} />
                <Route path="/our-supporters" component={OurSupporters} />
                <Route path="/donate" component={Donate} />
                <Route path="/donate/details" component={Details} />
                <Route path="/donate/payment" component={Payment} />
              </Switch>
              <Footer />
            </div>
          </ScrollToTop>
        </Analytics>
      </BrowserRouter>
    );
  }
}

export default App;
