import React from "react";
import Service from "../../api";
import FadeIn from "react-fade-in";
import Markdown from "react-markdown";

import Sutherland from "../../assets/images/sponsors/sutherland-self-help.jpg";
import Lamar from "../../assets/images/sponsors/lamar.jpg";

const fetchedDonorsData = [{ fetchedDonorsData: "" }];
class OurSupporters extends React.Component {
  state = {
    fetchedDonorsData,
  };

  componentDidMount() {
    this.fetchDonorsData();
  }

  async fetchDonorsData() {
    try {
      const donorsContent = await Service.get("donors-list");
      const fetchedDonorsData = donorsContent.data;
      this.setState({ fetchedDonorsData });
    } catch (e) {
      console.error(e);
    }
  }
  render() {
    const donors = this.state.fetchedDonorsData;
    return (
      <FadeIn childClassName="fade">
        <div className="our-supporters section top-section">
          <div className="sponsors section">
            <div className="sponsors__wrap container">
              <div>
                <h3>
                  Thank you to all of our incredible supporters.
                  <br /> We couldn’t build these new homes without you.
                </h3>
              </div>
              <div className="major-sponsors__list">
                <div className="major-sponsors__logo">
                  <img src={Lamar} alt="logo" />
                </div>
                <div className="major-sponsors__logo">
                  <img src={Sutherland} alt="logo" />
                </div>
              </div>
            </div>
            <div className="container">
              <div className="our-supporters__wrap">
                <Markdown source={donors.Donor} />
              </div>
            </div>
          </div>
        </div>
      </FadeIn>
    );
  }
}

export default OurSupporters;
