import React from "react";

class Hero extends React.Component {
  render() {
    return (
      <div className="hero top-section">
        <div className="container hero__wrap">
          <div className="hero__gradient"></div>
          <div className="hero__content">
            <h1>{this.props.heroTitle}</h1>
            <h4>{this.props.heroSubtitle}</h4>
          </div>
        </div>
      </div>
    );
  }
}

export default Hero;
