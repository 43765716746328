import React from "react";
import { Link } from "react-router-dom";
import { ShareButton } from "../buttons";
import ModalVideo from "react-modal-video";
import { FaRegPlayCircle } from "react-icons/fa";

import Residents from "../../assets/images/residents.jpg";
import Jessie from "../../assets/images/jessie.jpg";
import Shaun from "../../assets/images/shaun.jpg";

class Community extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    const data = this.props.fetchedCommunityData;
    return (
      <div className="community section">
        <div className="community__wrap container">
          <div className="community__header">
            <h3>Our Community</h3>
          </div>
          <h5>{data.introduction}</h5>
          <div className="community__block community__block--left first">
            <div className="community__image">
              <div>
                <ModalVideo
                  channel="vimeo"
                  isOpen={this.state.isOpenVimeo === "453714493"}
                  videoId="453714493"
                  onClose={() => this.setState({ isOpenVimeo: false })}
                />
                <div
                  className="video-placeholder"
                  onClick={() => this.setState({ isOpenVimeo: "453714493" })}
                >
                  <FaRegPlayCircle size={80} color="#fff" />
                  <img src={Jessie} alt="community" />
                </div>
              </div>
            </div>
            <div className="community__text">
              <h4>{data.videoTitleOne}</h4>
              <p>{data.videoBlurbOne}</p>
            </div>
            <div className="community__share">
              <ShareButton />
            </div>
          </div>
          <div className="community__block community__block--right">
            <div className="community__share">
              <ShareButton />
            </div>
            <div className="community__text">
              <h4>{data.videoTitleTwo}</h4>
              <p>{data.videoBlurbTwo}</p>
            </div>
            <div className="community__image">
              <div>
                <ModalVideo
                  channel="vimeo"
                  isOpen={this.state.isOpenVimeo === "453714873"}
                  videoId="453714873"
                  onClose={() => this.setState({ isOpenVimeo: false })}
                />
                <div
                  className="video-placeholder"
                  onClick={() => this.setState({ isOpenVimeo: "453714873" })}
                >
                  <FaRegPlayCircle size={80} color="#fff" />
                  <img src={Shaun} alt="community" />
                </div>
              </div>
            </div>
          </div>
          <div className="community__block community__block--left">
            <div className="community__image">
              <div>
                <ModalVideo
                  channel="vimeo"
                  isOpen={this.state.isOpenVimeo === "453707504"}
                  videoId="453707504"
                  onClose={() => this.setState({ isOpenVimeo: false })}
                />
                <div
                  className="video-placeholder"
                  onClick={() => this.setState({ isOpenVimeo: "453707504" })}
                >
                  <FaRegPlayCircle size={80} color="#fff" />
                  <img src={Residents} alt="community" />
                </div>
              </div>
            </div>
            <div className="community__text">
              <h4>{data.videoTitleThree}</h4>
              <p>{data.videoBlurbThree}</p>
            </div>
            <div className="community__share">
              <ShareButton />
            </div>
          </div>
          <div className="button-wrap button-center">
            <Link to={`/our-community`}>
              <div className="round-button">
                Meet more members of our community
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Community;
